<!--
 * @Description: TT 带货倾向分析
 * @Date: 2024-03-22 10:32:35
 * @LastEditTime: 2024-03-25 17:26:05
-->
<template>
  <div class="tt-loading-tendency-analysis">
    <view-header :isHome="false" ref="navheader" />
    <!-- <div class="gap"></div> -->

    <!-- 模块 1 -->
    <section class="banner-wrap">
      <div class="show-content">
        <video
          id="banner-video"
          src="https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/1711338452413/tt_banner_video.m4v"
          autoplay="autoplay"
          loop="loop"
          muted
        ></video>

        <img
          class="banner1"
          src="@/assets/img/tt-loading-tendency-analysis/banner-1.png"
        />
        <img
          class="banner2"
          src="@/assets/img/tt-loading-tendency-analysis/banner-2.png"
        />

        <img
          class="corner-bg"
          src="@/assets/img/tt-loading-tendency-analysis/coner-bg.png"
          alt=""
        />
      </div>

      <div class="banner-header_cont">
        <div class="banner-title">TikTok短视频带货倾向分析</div>
        <div class="banner-text">
          <div class="banner-item">
            分析TikTok短视频带货倾向，分析短视频中带货品类，并与已有货源进行关联
          </div>
        </div>
      </div>
    </section>

    <!-- <banner-head
      :title="'TikTok短视频带货倾向分析'"
      :desc="'分析TikTok短视频带货倾向，分析短视频中带货品类，并与已有货源进行关联'"
      :bannerImg="bannerBg"
    >
      <div class="header-custom" slot="content">
        <img
          class="banner1"
          src="@/assets/img/tt-loading-tendency-analysis/banner-1.png"
        />
        <img
          class="banner2"
          src="@/assets/img/tt-loading-tendency-analysis/banner-2.png"
        />
      </div>
    </banner-head> -->

    <!-- 核心功能 -->
    <div class="core-function">
      <common-title title="核心功能" />
      <column-two
        class="core-function_main"
        :list="spanList"
        :img="coreImg"
      ></column-two>
    </div>

    <!-- 应用场景 -->
    <div class="application-scene">
      <div class="application-scene-wrap">
        <CommonTitle title="应用场景" />

        <div class="application-scene-main">
          <div class="scene_1">
            <div class="image-content">
              <video
                src="https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/1711338413592/tt_app_video.m4v"
                autoplay
                loop
                muted
              ></video>
            </div>

            <div class="intro-content">
              <div class="text-container">
                <h6 class="subtitle">基于社媒的商机分析</h6>
                <p class="text">
                  基于社媒内容的播放、点赞、评论等分析商机，挖掘潜在爆款、助力选品
                </p>
              </div>
            </div>
          </div>
          <div class="scene_2">
            <div class="intro-content">
              <div class="text-container">
                <h6 class="subtitle">网红商家建联</h6>
                <p class="text">
                  实现规模化网红、商家建联，提升网红-商家撮合精准性及效率
                </p>
              </div>
            </div>

            <div class="image-content"></div>
          </div>

          <div class="scene_3">
            <video
              src="https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/1711338436147/tt_app_video_bg.m4v"
              autoplay="autoplay"
              loop="loop"
              muted
            ></video>

            <div class="image-content"></div>

            <div class="intro-content">
              <div class="text-container">
                <h6 class="subtitle">网红分销-内容变现</h6>
                <p class="text">快速实现网红存量内容的商品分销变现</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 尾部 -->
    <FooterVNode />
  </div>
</template>

<script>
import ViewHeader from '../../components/viewheader/viewheader.vue';
import BannerHead from './components/Banner.vue';
import CommonTitle from './components/CommonTitle.vue';
import ColumnTwo from './components/ColumuTwo.vue';
import FooterVNode from '@/components/foot/footer.vue';
// import bannerBg from '@/assets/img/tt-loading-tendency-analysis/banner-bg.gif';

export default {
  name: 'TTLoadingTendencyAnalysis',

  components: {
    ViewHeader,
    BannerHead,
    CommonTitle,
    ColumnTwo,
    FooterVNode,
  },

  data() {
    return {
      spanList: [
        {
          title: '短视频带货倾向分析',
          desc: '基于短视频标题及视频内容的语义理解，判断短视频带货倾向得分',
        },
        {
          title: '视频内容理解',
          desc: '分析视频内容，对于有带货倾向的视频分析其带货品类',
        },
        {
          title: '视频-商品货源关联',
          desc: '对于带货短视频，在自有商品池中（可自定义）关联同款/相似货源',
        },
      ],
      coreImg: require('@/assets/img/tt-loading-tendency-analysis/core-1.png'),
    };
  },
};
</script>

<style lang="less" scoped>
.tt-loading-tendency-analysis {
  .gap {
    height: 80px;
  }

  .banner-wrap {
    .show-content {
      position: relative;
      width: 1280px;
      height: 640px;
      left: 50%;
      transform: translateX(-50%);

      .corner-bg {
        width: 100%;
        height: 100%;
      }

      #banner-video {
        position: absolute;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        margin: auto;
      }

      img {
        position: absolute;
      }

      .banner1 {
        width: 38.67%;
        left: 1.87%;
        bottom: 1.87%;
      }

      .banner2 {
        width: 10.62%;
        right: 1.87%;
        bottom: 1.87%;
      }

      .corner-bg {
        left: 0;
        bottom: 0;
      }
    }
  }

  /* 核心功能 */
  .core-function {
    position: relative;
    padding: 80px 0 0;
    width: 1280px;
    margin: auto;

    .common-title {
      margin-bottom: 40px;
    }
    /deep/.common-two-column_text-item {
      margin-bottom: 60px;
    }

    /deep/.common-two-column_text {
      padding-top: 182px;
    }
  }

  /* 应用场景 */
  .application-scene {
    background-color: #f3f5f7;

    .application-scene-wrap {
      position: relative;
      padding: 80px 0;
      width: 1280px;
      margin: auto;
    }

    .common-title {
      margin-bottom: 40px;
    }

    .application-scene-main {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 40px;

      & > div {
        display: flex;
        border-radius: 16px;
        height: 500px;

        .image-content {
          flex: 1;
          width: 50%;

          video {
            width: 100%;
          }
        }

        .intro-content {
          width: 50%;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start; /* 水平方向左对齐 */

          .text-container {
            width: 63.9%;
            margin: auto;
            text-align: left;
          }

          .subtitle {
            font-weight: 600;
            font-size: 24px;
            line-height: 34px;
            color: #000108;
            margin-bottom: 8px;
          }

          .text {
            line-height: 22px;
            font-size: 16px;
            color: #494b5a;
          }
        }
      }

      .scene_1 {
        background-color: #fff;

        .image-content > video {
          border-radius: 16px;
        }
      }

      .scene_2 {
        background-image: url('~@/assets/img/tt-loading-tendency-analysis/app-scene-2-bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .text-container {
          .subtitle,
          .text {
            color: #fff;
          }
        }
      }

      .scene_3 {
        video {
          position: absolute;
          border-radius: 16px;
          width: 100%;
        }

        .intro-content {
          background: #fff;
          opacity: 0.85;
          margin: 24px 24px 24px 0;
          border-radius: 12px;
        }
      }
    }
  }

  @media screen and (max-width: 990px) {
    .banner-title {
      font-size: 0.64rem;
      text-align: center;
      margin-bottom: 0.266667rem;
    }

    .banner-text {
      font-size: 0.24rem;
      line-height: 0.373333rem;
      font-weight: 500;
      white-space: pre-line;
    }
  }

  @media screen and (min-width: 991px) and (max-width: 1280px) {
    .banner-title {
      font-size: 36px;
      margin-bottom: 16px;
    }
    .banner-text {
      font-size: 13px;
      line-height: 26px;
      font-weight: 500;
    }

    .banner-wrap {
      .show-content {
        width: 992px;
        height: 495.1px;
      }
    }

    .core-function {
      width: 992px;
    }

    .application-scene .application-scene-wrap {
      width: 992px;
    }

    .application-scene .application-scene-main {
      & > div {
        height: 387.5px !important;
      }
    }
  }

  @media screen and (min-width: 1440px) {
  }
}
</style>
